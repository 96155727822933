import { graphql } from 'gatsby';
import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

import Content, { HTMLContent } from '../components/Content';
import CourseActions from '../components/CourseActions';
import CourseGoals from '../components/CourseGoals';
import DefaultPageLayout from '../components/DefaultPageLayout';

interface Action {
  content: string;
  position: 0 | 1;
  to: string;
}

interface Props {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        actions: Action[];
        chapter?: string;
        date: Date;
        heading: string;
        goals: string[];
        subheading: string;
        title?: string;
      };
    };
  };
}

export default ({ data }: Props) => {
  const { frontmatter, html } = data.markdownRemark;

  let title = frontmatter.title;
  if (!title) {
    if (frontmatter.heading) {
      title += `${frontmatter.heading} `;
    }
    if (frontmatter.subheading) {
      title += ` | ${frontmatter.subheading}`;
    }
    if (title.length > 0) {
      title += ' | RxJS Course';
    }
  }

  let description = '';
  if (frontmatter.subheading) {
    description += `Learn ${frontmatter.subheading}`;
  }
  if (frontmatter.heading) {
    description += ` | ${frontmatter.heading} | RxJS Course`;
  }

  return (
    <DefaultPageLayout
      course
      title={title}
      description={description}
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
    >
      {frontmatter.goals && frontmatter.goals.length > 0 && (
        <CourseGoals goals={frontmatter.goals} />
      )}
      {html && html.length > 0 && (
        <Segment style={{ padding: '8em 0em' }} vertical>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row centered>
              <Grid.Column width={10}>
                <article>
                  <HTMLContent content={html} />
                </article>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )}
      {frontmatter.actions && frontmatter.actions.length > 0 && (
        <CourseActions actions={frontmatter.actions} />
      )}
    </DefaultPageLayout>
  );
};

export const pageQuery = graphql`
  query CoursePageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        actions {
          content
          position
          to
        }
        chapter
        date(formatString: "MMMM DD, YYYY")
        heading
        goals
        subheading
        title
      }
    }
  }
`;
