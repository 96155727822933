import { Link } from 'gatsby';
import React from 'react';
import { Button, Grid, Segment } from 'semantic-ui-react';

interface Action {
  content: string;
  position: 0 | 1;
  to: string;
}

interface Props {
  actions: Action[];
}

export default ({ actions }: Props) => {
  const prev = actions.find(action => action.position === 0);
  const next = actions.find(action => action.position === 1);

  return (
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row centered>
          <Grid.Column width={8}>
            {prev && (
              <Button
                as={Link}
                content={prev.content}
                to={`/course/${prev.to}`}
                icon="arrow left"
                labelPosition="left"
              />
            )}
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            {next && (
              <Button
                as={Link}
                content={next.content}
                to={`/course/${next.to}`}
                icon="arrow right"
                labelPosition="right"
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
