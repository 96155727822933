import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

interface Props {
  goals: string[];
}

export default ({ goals }: Props) => (
  <Segment style={{ padding: '8em 0em' }} vertical>
    <Grid container stackable verticalAlign="middle">
      <Grid.Row centered>
        <Grid.Column width={10}>
          <h2>Goals</h2>
          <ul>
            {goals.map((goal, i) => (
              <li key={i}>{goal}</li>
            ))}
          </ul>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);
